<template>
  <div class="main">
    <el-container>
      <el-header>我的技术笔记</el-header>
      <el-main>
        <h1>{{ msg }}</h1>
        <h1>更多内容陆续更新中</h1>
      </el-main>
      <div style="height:500px">
        python
      </div>
      <el-footer>      
        <a href="https://beian.miit.gov.cn/" target="_blank"> {{bahao}} </a>
        <br />
        <a href="https://beian.miit.gov.cn/" target="_blank"> {{bahao1}} </a>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      msg: '你好，欢迎光临我的技术空间, \n内容不断更新中',
      url: 'https://beian.miit.gov.cn/#/Integrated/index',
      bahao: '沪ICP备2022006478号',
      bahao1: '沪ICP备2022006478号-1'
    }
  },
  name: 'HelloWorld'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main .el-container {
  width: 100%;
  height: 100vh;
  background-color: #42b983;
}
.el-header {
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
}
.el-footer {
  z-index: 999;
  position: fixed;
  bottom: 20px;
  background-color: #fff;
  color: #333;
  text-align: center;
  line-height: 60px;
}
.el-aside {
  background-color: #D3DCE6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
  line-height: 160px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: px;
  background: #fff;
}
</style>
